import data from "./equipmentListData.js";
import upload from "../../../components/uploadUpgrade.vue";

export default {
	name: "equipmentList",
	data,
	created() {
		this.getList();
	},
	mounted() {},
	computed: {
		// dataList() {
		// 	this.checkAll = false;
		// 	this.checked = [];
		// 	this.isIndeterminate = false;
		// 	let eList = this.list.filter((i) => {
		// 		if (!this.selectEquipment) {
		// 			return true
		// 		} else if (this.selectEquipment == i.dno) {
		// 			return true
		// 		}
		// 	})
		// 	let cList = eList.filter((i) => {
		// 		if (!this.selectCompany) {
		// 			return true
		// 		} else if (this.selectCompany == i.companyName) {
		// 			return true
		// 		}
		// 	})
		// 	let pList = cList.filter((i) => {
		// 		if (!this.selectProject) {
		// 			return true
		// 		} else if (this.selectProject == i.projectName) {
		// 			return true
		// 		}
		// 	})
		// 	return pList;
		// }
	},
	methods: {
		// 获取数据列表
		getList() {
			// var 
			this.axios.get("dmlist/glist").then((res) => {
				this.list = [];
				this.equipmentList = [];
				this.getCompanyProject();
				if (res.status) {
					this.list = res.data;
					res.data.forEach((item) => {
						if (item.dno != '') {
							if (this.equipmentList.indexOf(item.dno) == -1) {
								this.equipmentList.push(item.dno)
							}
						}
					})
					this.queryData();
				}
			});
		},
		// 搜索
		queryData() {
			this.dataList = [];
			this.checkAll = false;
			this.checked = [];
			this.isIndeterminate = false;
			let eList = this.list.filter((i) => {
				if (!this.selectEquipment) {
					return true
				} else if (this.selectEquipment == i.dno) {
					return true
				}
			})
			let cList = eList.filter((i) => {
				if (!this.selectCompany) {
					return true
				} else if (this.selectCompany == i.companyName) {
					return true
				}
			})
			let pList = cList.filter((i) => {
				if (!this.selectProject) {
					return true
				} else if (this.selectProject == i.projectName) {
					return true
				}
			})
			this.dataHandle(pList, 'dataList');
		},
		tableSelect(selection) {
			this.checked = [];
			selection.forEach((el) => {
				this.checked.push(el.id);
			})
		},
		tableSelectAll(selection) {
			this.checked = [];
			selection.forEach((el) => {
				this.checked.push(el.id);
			})
		},
		// 列表数据处理，避免页面卡顿
		dataHandle(data = [], target = '') {
			if (target == '') {
				return [];
			}
			if (this.timerObj) {
				clearInterval(this.timerObj);
				this.timerObj = null;
			}
			let dataArr = this.deepCopy(data);
			let len = dataArr.length;
			let size = 150;
			let pageTotal = Math.ceil(len / size);
			let time = (4000 / pageTotal) >= 300 ? 300 : (3000 / pageTotal);
			let start = 0;
			let end = size - 1;
			let num = 1;

			this[target] = dataArr.filter((el, index) => {
				return index <= end && index >= start;
			})

			this.timerObj = setInterval(() => {
				if (num > pageTotal) {
					if (this.timerObj) {
						clearInterval(this.timerObj);
						this.timerObj = null;
					}
					return
				} else {
					start = end + 1 > len ? len : end + 1;
					end = end + size > len ? len : end + size;
					num += 1;
					let arr = dataArr.filter((el, index) => {
						return index <= end && index >= start;
					});
					arr.forEach((el, index) => {
						this.$set(this[target], start + index, el);
					})
					this.$nextTick(() => {
						this[target].forEach((el) => {
							if (this.checked.includes(el.id)) {
								this.$refs.multipleTable.toggleRowSelection(el, true);
							}
						})
					})
				}
			}, time);
		},
		// 获取公司项目数据
		getCompanyProject() {
			var data = {
				params: {
					loading: false
				}
			};
			this.axios.get('web2/cmn/odnr/gcpt', data).then((res) => {
				this.mCompanyList = [];
				this.companyList = [];
				this.getVersionNumber();

				if (res.status) {
					this.companyList = res.data;
					this.mCompanyList = res.data;
				}
			})
		},
		// 获取升级选择版本号列表和默认版本号列表
		getVersionNumber() {
			var data = {
				params: {
					loading: false
				}
			};
			this.axios.get('dmlist/gsysv', data).then((res) => {
				this.versionList = [];
				this.defaultVersionList = [];

				if (res.status) {
					this.currentDefaultVersion = res.data.default;
					this.versionList = res.data.list;
					this.defaultVersionList = res.data.list;
				}
			})
		},
		// 文件上传
		fileUpload() {
			var formdata = new FormData();
			var file = this.$refs.uploadUpgrade.getFile();
			if (this.versionNumber == "") {
				this.$message({
					showClose: true,
					message: '请输入版本号',
					type: 'warning'
				});
				return;
			} else if (file.length == 0) {
				this.$message({
					showClose: true,
					message: '请先选择文件',
					type: 'warning'
				});
				return;
			}

			file.forEach((i) => {
				formdata.append("file", i.raw);
			})
			formdata.append("version", this.versionNumber);

			this.axios.post('dmlist/upload', formdata).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '上传成功',
						type: 'success'
					});
					this.getVersionNumber();
					this.$refs.upgradeUploadR.doClose();
				}
			});
		},
		// 升级
		upgrade() {
			if (this.selectVersion == "") {
				this.$message({
					showClose: true,
					message: '请先选择版本号',
					type: 'warning'
				});
				return;
			} else if (this.checked.length == 0) {
				this.$message({
					showClose: true,
					message: '请先勾选设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				ids: this.checked.toString(),
				version: this.selectVersion,
			}
			this.axios.post('dmlist/upgrade', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '升级成功',
						type: 'success'
					});
					this.$refs.upgradeR.doClose();
				}
			});
		},
		// 修改
		revise() {
			let data = {};
			for (let k in this.info) {
				if (k != 'id') {
					if (this.info[k] != this.infoBackup[k]) {
						data[k] = this.info[k];
					}
				} else {
					data[k] = this.info[k];
				}
			}
			if ('projectId' in data) {
				data.projectId = data.projectId ? data.projectId : 0;
			}

			if (JSON.stringify(data) == "{}") {
				return
			}
			this.axios.post('dmlist/mdo', data).then(res => {
				if (res.status) {
					setTimeout(() => {
						this.setList();
					}, 300)
					this.$message({
						showClose: true,
						message: '修改成功',
						type: 'success'
					});
					this.dialogVisible = false;
				}
			});
		},
		// 设置默认版本
		selectDefault() {
			if (this.defaultVersion == "") {
				this.$message({
					showClose: true,
					message: '请先选择版本号',
					type: 'warning'
				});
				return;
			}
			let text = this.defaultVersion.split(' (');
			var data = {
				version: text[0],
			}
			this.axios.post('dmlist/mddsv', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '设置成功',
						type: 'success'
					});
					this.currentDefaultVersion = "L20-N OS " + data.version;
					this.$refs.defaultR.doClose();
				}
			});
		},
		// 打开数据零点校准开关
		calibrationOpen() {
			if (this.checked.length == 0) {
				this.$message({
					showClose: true,
					message: '请先勾选设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				type: 1,
				ids: this.checked.toString()
			}
			this.axios.post('dmlist/czo', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '开启成功',
						type: 'success'
					});
					this.getList();
				}
			});
		},
		// 关闭数据零点校准开关
		calibrationClose() {
			if (this.checked.length == 0) {
				this.$message({
					showClose: true,
					message: '请先勾选设备',
					type: 'warning'
				});
				return;
			}
			var data = {
				type: 0,
				ids: this.checked.toString()
			}
			this.axios.post('dmlist/czo', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '关闭成功',
						type: 'success'
					});
					this.getList();
				}
			});
		},
		// 打开弹窗
		openPopup(val, data, index) {
			this.reduction();
			this.mProjectList = [];
			if (val == '编辑') {
				this.listIndex = index;
				this.popupType = data.dno;
				for (let key in this.info) {
					if (data[key] !== undefined && data[key] != '-' && data[key] !== null) {
						this.info[key] = Number(data[key]);
						this.infoBackup[key] = Number(data[key]);
					}
				}
				this.companySelect(data.companyId, '编辑', 1)
			}
			this.dialogVisible = true;
		},
		// 全选设备
		handleCheckAll(val) {
			this.checked = [];
			if (val) {
				this.dataList.forEach((i) => {
					this.checked.push(i.id);
				})
			}
			this.isIndeterminate = false;
		},
		// 勾选多选框
		handleChecked(value) {
			// // console.log(value)
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.dataList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataList.length;
		},
		// 修改后本地更改列表数据
		setList() {
			if (this.info.projectId != this.infoBackup.projectId || this.info.companyId != this.infoBackup.companyId) {
				this.info.name = '';
				this.info.address = '';
			}
			for (let key in this.info) {
				if (key in this.dataList[this.listIndex]) {
					this.dataList[this.listIndex][key] = this.info[key];
				}
			}

			var clen = this.mCompanyList.length;
			var plen = this.mProjectList.length;
			for (let i = 0; i < clen; i++) {
				let item = this.mCompanyList[i];
				if (item.id == this.info.companyId) {
					this.dataList[this.listIndex].companyName = item.text;
					break;
				}
			}

			for (let l = 0; l < plen; l++) {
				let val = this.mProjectList[l];
				if (val.id == this.info.projectId) {
					this.dataList[this.listIndex].projectName = val.text;
					break;
				} else {
					this.dataList[this.listIndex].projectName = '';
				}
			}

			this.$forceUpdate()
		},
		// 公司选择后赋值项目选择列表
		companySelect(id, val, type = null) {
			// // console.log(id);
			if (val == '编辑') {
				this.mProjectList = [];
				var len = this.mCompanyList.length;
				for (let i = 0; i < len; i++) {
					let item = this.mCompanyList[i];
					if (item.id == id) {
						this.mProjectList = item.children;
						if (type == null) {
							this.info.projectId = null;
						}
						break
					}
				}
			} else if (val == '查询') {
				this.selectEquipment = '';
				this.selectProject = '';
				this.projectList = [];
				var len = this.companyList.length;
				for (let i = 0; i < len; i++) {
					let item = this.companyList[i];
					if (item.text == id) {
						this.projectList = item.children;
						this.selectProject = null;
						break
					}
				}
				this.queryData();
			}
		},
		// 关闭按钮弹窗
		closePopover() {
			var upgradeUploadR = this.popoverList.indexOf('upload');
			var upgradeR = this.popoverList.indexOf('upgrade');
			var defaultR = this.popoverList.indexOf('default');
			if (upgradeUploadR != -1) {
				this.$refs.upgradeUploadR.doClose();
				// this.popoverList.splice(1,upgradeUploadR);
			}
			if (upgradeR != -1) {
				this.$refs.upgradeR.doClose();
				// this.popoverList.splice(1,upgradeR);
			}
			if (defaultR != -1) {
				this.$refs.defaultR.doClose();
				// this.popoverList.splice(1,defaultR);
			}
		},
		// 按钮弹窗打开事件
		openPopover(name) {
			this.popoverList.push(name);
		},
		// 还原变量
		reduction(val = 'info') {
			this.popoverList = [];
			// // console.log(val);
			if (val == 'info') {
				for (let key in this.info) {
					this.info[key] = null;
				}
			} else if (val == 'upload') {
				this.versionNumber = "";
				this.$refs.uploadUpgrade.clearFile();

			} else if (val == "upgrade") {
				this.selectVersion = '';
			}
		},
	},
	components: {
		upload
	},
	beforeDestroy() {
		if (this.timerObj) {
			clearInterval(this.timerObj);
			this.timerObj = null;
		}
	},
	watch: {}
}
