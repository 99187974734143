<template>
	<div class="equipmentList-box">
		<header>
			<div class="seach-box">
				<el-select v-model="selectEquipment" clearable filterable placeholder="请选择设备" @change="queryData">
					<el-option v-for="(item, index) in equipmentList" :key="index" :label="item" :value="item"></el-option>
				</el-select>
				<el-select v-model="selectCompany" clearable filterable placeholder="请选择公司" @change="companySelect($event, '查询')">
					<el-option v-for="(item, index) in companyList" :key="index" :label="item.text" :value="item.text"></el-option>
				</el-select>
				<el-select v-model="selectProject" clearable filterable placeholder="请选择项目" :disabled="this.projectList.length == 0" @change="queryData">
					<el-option v-for="(item, index) in projectList" :key="index" :label="item.text" :value="item.text"></el-option>
				</el-select>
			</div>
			<div class="right-box">
				<el-popover popper-class="operationplacement" placement="left" width="400" trigger="click" :enterable="true">
					<div class="popover-button-box" @click="closePopover">
						<div class="one-box butsBox">
							<el-divider content-position="left">升级操作</el-divider>
							<el-popover
								ref="defaultR"
								placement="bottom"
								width="300"
								trigger="click"
								@after-enter="openPopover('default')"
								@hide="reduction('default')"
								:enterable="true"
							>
								<div class="default-box">
									<div class="content-box">
										<p class="current-default">
											<span>当前默认版本：</span>
											<span>{{ currentDefaultVersion }}</span>
										</p>
										<el-divider class="top-divider" content-position="left">选择默认版本</el-divider>
										<el-select v-model="defaultVersion" filterable placeholder="请选择默认版本号">
											<el-option v-for="(item, index) in defaultVersionList" :title="'版本号：' + item" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</div>
									<el-button type="primary" size="small" @click="selectDefault">确定</el-button>
								</div>
								<el-button slot="reference" class="default-but but-margin" type="success" size="small">默认版本</el-button>
							</el-popover>
							<el-popover ref="upgradeUploadR" placement="bottom" width="300" trigger="click" @after-enter="openPopover('upload')" @hide="reduction('upload')">
								<div class="upgrade-upload-box">
									<div class="content-box">
										<el-divider class="top-divider" content-position="left">版本号</el-divider>
										<el-input v-model="versionNumber" placeholder="请输入上传版本号"></el-input>
										<upload ref="uploadUpgrade" :configuration="configuration"></upload>
									</div>
									<el-button type="success" size="small" @click="fileUpload">上传</el-button>
								</div>

								<el-button class="but-margin" slot="reference" type="primary" size="small">上传文件</el-button>
							</el-popover>
							<el-popover ref="upgradeR" placement="bottom" width="300" trigger="click" @after-enter="openPopover('upgrade')" @hide="reduction('upgrade')">
								<div class="upgrade-box">
									<div class="content-box">
										<el-divider class="top-divider" content-position="left">
											选择版本
											<i class="tipstxt red">*（升级功能只对在线设备有效）</i>
										</el-divider>
										<el-select v-model="selectVersion" filterable placeholder="请选择升级版本号">
											<el-option v-for="(item, index) in versionList" :title="'版本号：' + item" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</div>
									<el-button type="primary" size="small" @click="upgrade">升级</el-button>
								</div>
								<el-button slot="reference" class="but but-margin" type="success" size="small">升级</el-button>
							</el-popover>
						</div>
						<div class="two-box butsBox">
							<el-divider content-position="left">数据零点校准开关</el-divider>
							<el-button type="primary" size="small" @click="calibrationOpen">开启</el-button>
							<el-button type="warning" size="small" @click="calibrationClose">关闭</el-button>
						</div>
					</div>
					<el-button slot="reference" class="default-but" type="success" size="small">操作</el-button>
				</el-popover>
			</div>
		</header>
		<div class="scroll-box">
			<el-table ref="multipleTable" :data="dataList" stripe tooltip-effect="dark" style="width: 100%" height="100%" @select="tableSelect" @select-all="tableSelectAll">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
				<el-table-column prop="name" label="设备编号" width="120" align="center">
					<template slot-scope="scope">
						{{ scope.row.dno | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column label="状态" width="60" align="center">
					<template slot-scope="scope">
						<span class="font-w">
							<i v-show="scope.row.online == 0" class="icon iconfont icon-xinhao"></i>
							<i v-show="scope.row.online == 1" class="icon iconfont icon-xinhao green"></i>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="companyName" label="公司" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.companyName | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column prop="projectName" label="项目" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.projectName | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column prop="name" label="测点" width="120px" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.name | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column prop="address" label="名称/位置" width="140px" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.address | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column prop="number" label="短信数" width="80px" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.number | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column prop="version" label="版本号" width="140px" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						{{ scope.row.version | dataDetect }}
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="100px" show-overflow-tooltip>
					<template slot-scope="scope">
						<div class="operation-box">
							<el-popover placement="left" width="400" trigger="hover" popper-class="equipment-parameter">
								<div class="extraContent-box">
									<p class="cell-box">
										<span class="nameText">零点数据校准开关：</span>
										<span class="contentText">{{ scope.row.cztp == 0 ? '关' : '开' }}</span>
									</p>
								</div>
								<i slot="reference" class="pointer el-icon-view green" title="查看" @click="setEquipmentList(scope.row.dids)"></i>
							</el-popover>
							<i class="pointer el-icon-edit outline" title="编辑" @click="openPopup('编辑', scope.row, scope.$index)"></i>
							<!-- <i class="pointer el-icon-delete delete red" title="删除" @click="deleteDevice(i.id)"></i> -->
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 修改弹窗 -->
		<el-dialog width="450px" :title="popupType" :visible.sync="dialogVisible" custom-class="dialog-revise" :show-close="false" :close-on-click-modal="false">
			<div class="device-revise-box">
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<i class="red">*</i>
							公司：
						</div>
					</el-col>
					<el-col :span="12" class="grid-content-box">
						<div class="grid-content">
							<el-select v-model="info.companyId" filterable placeholder="请选择公司" @change="companySelect($event, '编辑')">
								<el-option v-for="(item, index) in mCompanyList" :title="'公司：' + item.text" :key="index" :label="item.text" :value="Number(item.id)"></el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<!-- <i class="red">*</i> -->
							项目：
						</div>
					</el-col>
					<el-col :span="12" class="grid-content-box">
						<div class="grid-content">
							<el-select v-model="info.projectId" clearable filterable placeholder="请选择项目" :disabled="this.mProjectList.length == 0">
								<el-option v-for="item in mProjectList" :title="'项目：' + item.text" :key="item.id" :label="item.text" :value="Number(item.id)"></el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="5" class="grid-name-box">
						<div class="grid-name">
							<i class="red">*</i>
							短信数：
						</div>
					</el-col>
					<el-col :span="12" class="grid-content-box">
						<div class="grid-content"><el-input v-model="info.number" placeholder="请输入每月短信发送总数"></el-input></div>
					</el-col>
				</el-row>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="revise">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import equipmentList from './equipmentList.js';
export default equipmentList;
</script>

<style scoped lang="scss">
@import './equipmentList.scss';
</style>

<style lang="scss">
// 操作按钮弹窗
.operationplacement {
	padding-top: 0;
	transform: translateY(54px);

	.popper__arrow {
		transform: translateY(-54px);
	}

	.but-margin {
		margin-right: 10px;
	}
}
// 弹窗内容
.popover-button-box {
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: column;

	// 分割线
	.el-divider--horizontal {
		margin: 24px 0 20px 0 !important;
	}

	.butsBox {
		width: 100%;
	}
}
//选择默认版本号弹窗
.default-box {
	height: 140px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	.content-box {
		width: 100%;
		// height: 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.current-default {
			width: 100%;
			padding-top: 15px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.el-select,
		.el-input__inner {
			width: 220px;
			margin-bottom: 5px;
		}

		.el-select {
			.el-input__icon {
				height: auto;
				line-height: 35px !important;
			}

			.el-input__inner {
				width: 100%;
				height: 35px !important;
				line-height: 35px;
			}
		}

		.el-divider {
			margin: 20px 0 20px 0;
		}

		.tipstxt {
			font-size: 12px;
		}

		.el-divider__text {
			font-size: 13px;
		}
	}
}
// 文件上传弹窗样式
.upgrade-upload-box {
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// align-items: center;

	.content-box {
		width: 100%;
		height: 140px;
		display: flex;
		flex-direction: column;
		// justify-content: space-around;
		// align-items: center;

		.el-divider {
			margin: 20px 0;
		}

		.el-divider__text {
			font-size: 13px;
		}

		.top-divider {
			margin-top: 10px !important;
		}
	}
}

// 升级弹窗
.upgrade-box {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	.content-box {
		width: 100%;
		// height: 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.el-select,
		.el-input__inner {
			width: 220px;
		}

		.el-divider {
			margin: 5px 0 20px 0;
		}

		.tipstxt {
			font-size: 12px;
		}

		.el-divider__text {
			font-size: 13px;
		}
	}
}

// 列表多余数据展示弹窗
.extraContent-box {
	width: 100%;

	.cell-box {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;

		.nameText {
		}

		.contentText {
		}
	}
}
</style>
