<template>
	<div class="file-upload-box">
		<el-divider v-if="txtShow" class="tips-text" content-position="left">{{ title }}</el-divider>
		<el-upload
			class="upload-demo"
			action=""
			:multiple="configuration.limit > 1 ? true : false"
			:limit="configuration.limit"
			:file-list="fileList"
			:auto-upload="true"
			:accept="configuration.type"
			:on-exceed="beyond"
			:on-change="setFile"
			:on-remove="clearFile"
			:before-upload="checkexFile"
			:http-request="httpUpload"
			:class="configuration.name"
			:ref="configuration.name"
		>
			<el-button size="small" type="primary">{{ configuration.butText }}</el-button>
		</el-upload>
		<el-dialog :modal-append-to-body="false" :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
	</div>
</template>

<script>
export default {
	name: 'uploadFile',
	props: {
		configuration: {
			type: Object,
			default: () => {
				return {
					limit: 1, //最大文件个数
					name: 'file', //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
					butText: '选择文件',
					type: '' // 文件类型  .pdf, .doc, .docx, .xls, .xlsx .jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF 详细MIME类型 看https://zhuanlan.zhihu.com/p/268822410
				};
			}
		},
		// 顶部文字是否显示
		txtShow: {
			type: Boolean,
			default: true
		},
		// 顶部文字
		txt: {
			type: String,
			default: '文件上传'
		}
	},
	data() {
		return {
			fileList: [],
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
			title: '',
			type: '' // 文件类型
		};
	},
	created() {
		this.title = this.txt;
		if (this.configuration.hasOwnProperty('type')) {
			this.type = this.configuration.type;
		} else {
			this.type = '';
		}
	},
	mounted() {},
	computed: {},
	methods: {
		// 获取上传文件
		getFile() {
			return this.fileList;
		},
		// 清除文件
		clearFile() {
			// // console.log(this.$refs[this.configuration.name])
			this.$refs[this.configuration.name].clearFiles();
			this.fileList = [];
			this.hideFile();
		},
		// 文件上传数据赋值
		setFile(file, fileList) {
			this.fileList = fileList;
			this.hideFile();
			// // console.log(fileList);
		},
		// 是否隐藏文件上传框
		hideFile() {
			if (this.fileList.length >= this.configuration.limit) {
				$('.' + this.configuration.name)
					.find('.el-upload')
					.css({
						display: 'none'
					});
				this.title = ' 文件';
			} else {
				setTimeout(() => {
					$('.' + this.configuration.name)
						.find('.el-upload')
						.css({
							display: 'inline-block'
						});
					this.title = ' 文件上传';
				}, 400);
			}
		},
		// 检查上传文件
		checkexFile(file) {
			// console.log("文件")
			// console.log(file);
			if (this.type != '') {
				var index = file.name.lastIndexOf('.');
				var type = file.name.substring(index);
				let arr = this.type.split(',');
				if (!arr.includes(type)) {
					this.$message({
						showClose: true,
						message: `请上传${this.type}类型的文件`,
						type: 'warning'
					});
					return false;
				}
			}
			// console.log(this.fileList)
			let len = this.fileList.length;
			if (len > 1) {
				for (let i = 0; i < len; i++) {
					let item = this.fileList[i];
					if (item.name == file.name) {
						this.$message({
							showClose: true,
							message: '请不要上传相同文件',
							type: 'warning'
						});
						return false;
					} else {
						return true;
					}
				}
			} else {
				return true;
			}
		},
		// 移除文件
		handleRemove(file) {
			var len = this.fileList.length;
			for (let i = 0; i < len; i++) {
				if (this.fileList[i].name == file.name) {
					this.fileList.splice(i, 1);
					break;
				}
			}
			this.hideFile();
			// // console.log(file);
		},
		// 自定义上传行为
		httpUpload() {
			return false;
		},
		// 预览图片
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		// 超出最大文件数
		beyond(files, fileList) {
			// // console.log(fileList)
			this.$message({
				showClose: true,
				message: `所选文件数超出最大限制数（${this.configuration.limit}个），请重新选择！`,
				type: 'warning'
			});
		},
		// 获取配置参数
		getConfiguration() {
			return this.configuration;
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {
		configuration: function(nv) {
			// // console.log(nv)
			if (nv.hasOwnProperty('type')) {
				this.type = nv.type;
			} else {
				this.type = '';
			}
		}
	}
};
</script>

<style scoped lang="scss">
.file-upload-box {
	width: 100%;
}
</style>
