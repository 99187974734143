export default () => {
	return {
		list:[],//列表数据
		dataList: [],//列表展示数据
		timerObj: null,// 数据处理定时器
		companyList: [],// 公司搜索选择列表
		selectCompany: '',// 公司搜索选中数据
		projectList: [],// 项目搜索选择列表
		selectProject: '',// 项目搜索选中数据
		equipmentList: [],// 设备选择列表
		selectEquipment: '',// 设备选中数据
		versionNumber: '',// 输入的上传的升级版本号
		versionList: [],//升级版本号选择列表
		selectVersion: '',//选择的升级版本号
		currentDefaultVersion: '',//默认版本号
		defaultVersion: '',//选择的默认版本号
		defaultVersionList: [],//默认版本号选择列表
		checkAll: false,
		checked: [],
		isIndeterminate: false,
		dialogVisible: false, // 修改弹窗是否显示
		popoverList: [], // 打开的按钮弹窗列表
		popupType: '修改配置', // 弹窗类型
		info: {
			companyId: null, //公司id
			projectId: null, //项目id
			number: null, //短信数量
			id: null
		}, // 新增/修改上传参数
		infoBackup: {
			companyId: null, //公司id
			projectId: null, //项目id
			number: null, //短信数量
			id: null
		},// 新增/修改上传参数备份
		listIndex: null,// 选择修改的列表数据索引
		mCompanyList: [],// 公司选择列表
		mProjectList: [],// 项目选择列表
		configuration:{
			limit: 1, //最大文件个数
			name: 'file',//上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			butText: '选择文件'
		},// 文件上传组件参数
	}
}
